<template>
  <v-layout wrap>
    <v-flex xs8
      style="padding:20px 10px 20px 20px;height:calc(100vh - 65px);overflow-y: auto;overflow-x: hidden;scrollbar-width: none !important;"
    >
       
      <v-card elevation="0">
        <v-card-title>
          {{ title }}
          <v-spacer />
          <search :onSearch="handleSearch" />
          <v-btn
            v-if="!isReadonly"
            color="primary"
            fab
            elevation="0"
            small
            @click="handleAddFolder"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="handleAddFolder"
          >
            {{ $t('common.newItem', locale) }}
          </v-btn> -->
        </v-card-title>
        <v-card-text style="padding:0px;">
          <draggable-table
            :data="data"
            :parentID="null"
            :count="data.filter(x => !x.ID)[0].ItemsCount"
            :onCancelEdit="handleCancelEdit"
            :onShowMore="handleShowMore"
            :onSortItems="isReadonly ? () => {} : handleSortItems"
            :onSortStart="handleSortStart"
            :activeDragable="activeDragable"
            :onGetData="handleGetData"
            :onEditItem="isReadonly ? null : handleEditItem"
            :onDeleteItem="isReadonly ? null : handleDeleteItem"
            :onAddSubitem="isReadonly ? null : handleAddSubitem"
            :isSearchResult="isSearchResult"
            table="folder"
          />
          <!-- <draggable v-model="folders" draggable=".item">
            <div v-for="folder in folders" :key="folder.ID" class="item" style="padding:20px; border:1px solid black">
                {{ folder.Name}}
            </div>            
          </draggable> -->
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs4 style="padding:20px 20px 20px 10px;">
      <v-card
        style="height:calc(100vh - 104px);overflow-y: auto;overflow-x: hidden;scrollbar-width: thin !important;"
        elevation="0"
      >
        <v-card-text v-if="!isReadonly">
          <folder-form
            v-if="showFolderForm" 
            :data="editedItem"
            :onClose="handleCloseForms"
            :onDelete="handleDeleteItem"
            :level="editedItem"
          />
          <file-form
            v-else-if="showFileForm" 
            :data="editedItem"
            :onClose="handleCloseForms"
            :onDelete="handleDeleteItem"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import utils from '@/services/utils'
  import DraggableTable from './draggableTable/Index'
  import FolderForm from './FolderForm'
  import FileForm from './FileForm'
  import Search from '@/components/Search'

  export default {
    components: {
      DraggableTable,
      FolderForm,
      FileForm,
      Search
    },    
    data () {
      return {
        isReadonly: true,
        activeDragable: '-1', // enable sorting
        title: '', // title renameFolders
        data: [{
          ID: null,
          items: [],
          ItemsCount: null,
          startIndex: 0
        }],
        itemsPerPage: 10,
        showFileForm: false,
        showFolderForm: false,
        editedItem: null,
        isSearchResult: false,
        search: null
      }
    },
    computed: {        
      ...mapState('app', ['locale']),
      workspaceID () {
        return this.$store.state.app['dfilesWorkspace'] 
      },
      userID () {
        return utils.getUser()
      },
    },
    mounted () {
      // get title renameFolders
      api.getItem ('dfiles', `v1/private/workspaces/`, this.workspaceID)
        .then(response => {
          this.title = response && response.RenameFolders ? response.RenameFolders : this.$t('folders.title', this.locale) 
        })
      this.handleGetIsReadonly()
      this.handleGetData(null)
    },
    methods: {
      handleGetIsReadonly() {
        api.getAllWithoutLimit ('dfiles', `v1/internal/users/${this.userID}/check-readonly`)
          .then(response => {
            this.isReadonly = response === false ? false : true
          })
      },
      handleSearch (v) {
        this.isSearchResult = v.length > 0
        this.search = this.isSearchResult ? v : null
        this.data = [{
          ID: null,
          items: [],
          ItemsCount: null,
          startIndex: 0
        }],
        this.handleGetData(null)
      },
      handleShowMore (parentID) {
        const item = this.data.filter(x => x.ID === parentID)[0]
        if (!item.startIndex || item.startIndex === 'undefined') item.startIndex = 0
        
        item.startIndex = item.startIndex + this.itemsPerPage
        this.handleGetData(parentID)
      },
      handleGetData (parentID) {
        const item = this.data.filter(x => x.ID === parentID)[0]
        if (!item.CountFile || item.CountFile === 0)
          this.handleGetFolders(parentID, item)
        else 
          this.handleGetFiles(parentID, item)
      },
      handleGetFolders (parentID, item) {        
        api.getAll ('dfiles', `v1/private/folders/${parentID}/`, item.startIndex, this.itemsPerPage, !parentID && this.search ? this.search : '')
        .then(response => {
          item.ItemsCount = response.count
          this.data.push(...response.data)
        })
      },
      handleGetFiles (parentID, item) {        
        api.getAll ('dfiles', `v1/private/files/${parentID}/`, item.startIndex, this.itemsPerPage, '')
        .then(response => {
          item.ItemsCount = response.count
          this.data.push(...response.data)
        })
      },
      handleAddSubitem(type, v, level) {
        this.handleCancelEdit()
        switch (type) {
          case 'folder': {
            this.editedItem = {
              ID: null,
              Name: { 'es': '' },
              ParentFolderID: v,
              Level: level,
            }
            this.showFolderForm = true
            this.showFileForm = false
            break
          }
          case 'file': {
            this.editedItem = {
              ID: null,
              Name: { 'es': '' },
              ParentFolderID: v,
              Level: level
            }
            this.showFileForm = true
            this.showFolderForm = false
            break
          }
        }
      },
      handleAddFolder () {
        this.handleCancelEdit()
        this.editedItem = {
          ID: null,
          Name: { 'es': '' },
          Level: 0,
          Visibility: 'privateByUser'
        }
        this.showFolderForm = true
      },
      handleEditItem (v) {
        this.handleCancelEdit()
        this.editedItem = v
        if (v.type === 'folder') this.showFolderForm = true
        else this.showFileForm = true
      },
      handleDeleteItem (id) {
        this.handleCancelEdit()
        const item = this.data.filter(x => x.ID === id)[0]
        if (item.type === 'file')
          api.deleteItem ('dfiles', 'v1/private/files/', id)
          .then(response => {
            this.handleCloseForms('delete', response.data)
          })
        else
          api.deleteItem ('dfiles', 'v1/private/folders/', id)
          .then(response => {
            this.handleCloseForms('delete', response.data)
          })

      },
      handleCloseForms (action, v) {
        if (v)
          switch (action) {
            case 'add': {
              this.data.unshift(v)
              const aux = this.data.filter(x => x.ID === v.ParentFolderID)[0]
              if (aux) {
                if (v.type === 'file') aux.CountFile++
                else aux.CountFolder++
                aux.ItemsCount = aux.ItemsCount ? aux.ItemsCount + 1 : 1
                aux.expand = true
              }              
              break
            }              
            case 'update': {
              const index = this.data.findIndex(x => x.ID === v.ID)
              this.data.splice(index, 1)
              this.data.splice(index, 0, v)
              // this.data[index] = v
              // console.log('jhm', this.data[index].Name.es)
              // // this.data.filterin(x => x.ID === v.ID)[0] = JSON.parse(JSON.stringify(v))
              // // console.log(this.data.filter(x => x.ID === v.ID)[0].Name.es)
              break
            }              
            case 'delete': {
              const itemToDelete = this.data.filter (x => x.ID === v)[0]
              const aux = this.data.filter(x => x.ID === itemToDelete.ParentFolderID)[0]
              if(aux) {
                if (itemToDelete.type === 'file') aux.CountFile--
                else aux.CountFolder--
                aux.ItemsCount--
              }
              this.data = this.data.filter (x => x.ID !== v)
              break
            }
          }
        this.showFolderForm = false
        this.showFileForm = false
      },
      handleSortStart (v) {
        this.activeDragable = v // disable sorting until the action is finished
      },
      handleSortItems (table, v){
        this.handleCancelEdit()
        // INI - update data in memory with tmp value
        const aux = this.data.filter (x => v.map(item => { return item.ID}).indexOf(x.ID) < 0)
        aux.push(...v)
        this.data = aux
        // END - update data in memory with tmp value

        this.activeDragable = '-1' // enable sorting once the action is finished

        const body = []
        const maxValue = Math.max.apply(null, v.map((o) => { return o.ORD }))
        for (let i = 0; i < v.length; i++)
          body.push ({ ID: v[i].ID, ORD: maxValue - i})
          
        switch (table) {
          case 'folder':
            api.put ('dfiles', 'v1/private/folders/sort', body)          
            break
          case 'file':
            api.put ('dfiles', 'v1/private/files/sort', body)          
            break
          default:
            alert('no def')
            break
        }
      },
      handleCancelEdit () {
        this.showFolderForm = false
        this.showFileForm = false
      }
    }
  }
</script>
